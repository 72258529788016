.addBitRate {
  height: 36px;
  width: 100%;
  border: 1px dashed #333;
  text-align: center;
  border-radius: 8px;
  // line-height: 36px;
  margin-top: 10px;
  // margin: 0 80px;
}
.notice {
  margin-bottom: 20px;
}
.title {
  font-weight: 500;
  font-size: 16px;
}
.desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
