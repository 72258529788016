.topTitle {
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  background: linear-gradient(360deg, #8188ff 0%, #a78dff 100%) !important;
  opacity: 0.8;
  margin-bottom: 30px;
}
.allProd {
  display: flex;
  justify-content: space-around;
}
.square {
  width: 260px;
  height: 164px;
  border: 1px solid #bbb;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.right {
  display: inline-block;
  margin-left: 40px;
}
.detail {
  cursor: pointer;
}
