.headerBtn {
  display: flex;
  justify-content: space-between;
  button {
    width: 150px;
    height: 36px;
    margin-top: 7px;
  }
}
.demo-loadmore-list {
  padding: 0 100px;
}
.msg-content {
  height: 188px;
  overflow: auto;
}
