.formSty {
  .ant-form-item-label > label {
    width: 120px;
    justify-content: flex-end;
  }
  #content {
    width: 100% !important;
    padding-right: 20px;
    border-radius: 5px;
  }
  #nickname {
    width: 280px;
  }
  #title {
    padding-right: 20px;
    width: 100% !important;
    margin-right: 120px;
  }
  .ant-select {
    width: 280px !important;
  }
}
.titleOverSty {
  .ant-tooltip-inner {
    max-height: 300px !important;
    overflow: auto !important;
    white-space: pre-wrap;
  }
}
.operate-space {
  margin-left: 10px;
}
.msgInfo {
  word-wrap: break-word;
  word-break: break-all;
}
