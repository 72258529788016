.styText {
  height: 120px !important;
  width: 180px;
}
.bottom {
  height: 340px;
  width: 100%;
  border: solid 1px #efefef;
  border-radius: 5px;
  position: relative;
}
.code {
  height: 280px;
  overflow-y: auto;
}
.styButton {
  display: block;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.downButton {
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
}
.item {
  // width: 100%;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
}
