.wrapped-content {
  .ant-modal-header {
    background: #7b82ff;
  }
  .ant-modal-close {
    color: #fff;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
  .ant-modal-footer {
    text-align: center;
    height: 64px;
    padding: 0;
    button {
      margin-right: 40px;
      width: 150px;
      height: 36px !important;
      font-size: 16px;
      border-radius: 4px;
      font-weight: 500;
      margin-top: 14px;
    }
  }
  :global(.ant-modal-header) {
    background: #7b82ff;
    text-align: center;
  }
}
.closed {
  color: #9cb6e6;
}
