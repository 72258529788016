.pagination {
  margin-top: 30px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .table-wrapped {
//   :global(.ant-table-thead > tr > th) {
//     background: none;
//     color: #637698;
//     border-bottom-style: dashed;
//     border-bottom-color: #3e495b;
//   }
//   :global(.ant-table-tbody > tr > td) {
//     border-bottom: none;
//     color: #9cb6e6;
//   }
//   :global(.ant-table-small) {
//     border: none;
//   }
//   :global(.ant-table-small > .ant-table-content > .ant-table-body) {
//     margin: 0;
//   }
//   :global(.ant-pagination-next .ant-pagination-item-link) {
//     color: #9cb6e6;
//   }
//   :global(.ant-pagination-next:hover .ant-pagination-item-link) {
//     color: #4f7aee;
//   }
//   :global(.ant-pagination-prev .ant-pagination-item-link) {
//     color: #9cb6e6;
//   }
//   :global(.ant-pagination-prev:hover .ant-pagination-item-link) {
//     color: #4f7aee;
//   }
//   :global(.ant-pagination-disabled, .ant-pagination-disabled:hover) {
//     cursor: not-allowed;
//   }
//   :global(.ant-pagination-item) {
//     a {
//       color: #637698;
//     }
//   }
//   :global(.ant-pagination-item:hover a) {
//     color: #9cb6e6;
//   }
//   :global(.ant-pagination-item-active) {
//     background: #4f7aee;
//     a {
//       color: #fff;
//     }
//   }
//   :global(.ant-table-tbody
//       > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
//       > td) {
//     background-color: #3e495b;
//   }
//   :global(.ant-pagination-jump-next
//       .ant-pagination-item-container
//       .ant-pagination-item-ellipsis) {
//     color: #637698;
//   }
//   :global(.ant-pagination-jump-prev
//       .ant-pagination-item-container
//       .ant-pagination-item-ellipsis) {
//     color: #637698;
//   }
//   :global(.ant-table-thead
//       > tr
//       > th
//       .ant-table-column-sorter
//       .ant-table-column-sorter-inner) {
//     color: #637698;
//   }
//   :global(.ant-table-thead
//       > tr
//       > th
//       .ant-table-column-sorter
//       .ant-table-column-sorter-inner
//       .ant-table-column-sorter-up.on) {
//     color: #4f7aee;
//   }
//   :global(.ant-table-thead
//       > tr
//       > th
//       .ant-table-column-sorter
//       .ant-table-column-sorter-inner
//       .ant-table-column-sorter-down.on) {
//     color: #4f7aee;
//   }
//   :global(.ant-table-fixed-header
//       > .ant-table-content
//       > .ant-table-scroll
//       > .ant-table-body) {
//     background: #2b3648;
//   }
// }

.pagination-total-text {
  color: #637698;
}
