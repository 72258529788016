.styButton {
  display: block;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.return {
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
.pagination {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-picker {
  width: 280px !important;
}
