.login {
  background: #efefef;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .login_box {
    display: flex;
    // border-radius: 4px;
  }
  .login_top {
    height: 50px;
    width: 400px;
    text-align: center;
    line-height: 50px;
    border-bottom: 1px solid #efefef;
  }
  .login_left {
    width: 250px;
    height: 348px;
    border-radius: 10px 0px 0px 10px;
    img {
      width: 100%;
      height: 100%;
    }
    background: #bbb;
  }
  .login_right {
    background: #fff;
    width: 400px;
    border-radius: 0px 10px 10px 0px;
  }

  .submit_form {
    margin-top: 30px;
  }
  // .login_box {
  //   width: 300px;
  // }
}
.submit_form {
  padding: 0 20px;
}
.form-input {
  line-height: 40px;
}
