.operate-space {
  margin-right: 5px;
}
.demo-loadmore-list1 {
  padding: 0 80px;
}
.return {
  span:nth-child(2) {
    margin-left: 20px;
  }
  font-size: 16px;
  width: 140px;
  font-weight: 500;
  height: 40px;
  // line-height: 40px;
}
.msgInfo {
  white-space: pre-line;
}
