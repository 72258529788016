.form {
  .ant-input {
    width: 280px !important;
    border-radius: 5px !important;
  }
  .ant-input-number {
    width: 280px !important;
    border-radius: 5px !important;
  }
  .ant-select {
    width: 280px !important;
    border-radius: 5px !important;
  }
  .ant-form-item-label > label {
    width: 120px;
    justify-content: flex-end;
  }
}
.ant-picker {
  border-radius: 5px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}
.styButton {
  button {
    width: 140px !important;
    border-radius: 5px !important;
  }
  justify-content: flex-end;
}
