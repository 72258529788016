.logo {
  height: 48px;
  margin: 8px;
  font-weight: bold;
  background: rgba(231, 213, 213, 0.3);
  text-align: center;
  line-height: 48px;
  color: #fff !important;
  border-radius: 5px;
}
