@import "~antd/dist/antd.css";
body {
  font-size: 14px;
  font-family: Helvetica-Bold;
  // color: #fff;
  font-family: "微软雅黑";
  // background-color: #efefef;
}
body,
html {
  height: 100%;
}
