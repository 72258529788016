#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 62px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 20px;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
.header {
  display: flex;
  height: 100%;
}
.header-left {
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: #fff;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-radius: 5px !important;
}
.header-right {
  padding-right: 20px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}
.user {
  line-height: 62px;
  margin-left: 15px;
}
.layout {
  width: 100px;
  text-align: center;
}
.site-layout-plat {
  width: 56px;
  text-align: left;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  border-radius: 5px !important;
}
.bread a {
  color: #333 !important;
}
// 分页按钮
.ant-pagination-item-active {
  border-color: #7b82ff !important;
  color: #fff !important;
}
.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  > a,
  > .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;
  }

  &.ant-pagination-item-active {
    background: linear-gradient(360deg, #8188ff 0%, #a78dff 100%);
    > a {
      color: #fff !important;
    }
  }
  &.ant-pagination-disabled {
    background: rgba(151, 151, 151, 0.15);
    &:hover > a {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
// Menu
.ant-menu {
  // 横版
  &.ant-menu-horizontal {
    .ant-menu-item {
      font-size: 16px;
      text-align: center;
    }
  }
  // 黑色主题&横版
  &.ant-menu-dark.ant-menu-horizontal {
    .ant-menu-item {
      &.ant-menu-item-selected {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          background: linear-gradient(
            360deg,
            #8188ff 0%,
            #a78dff 100%
          ) !important;
        }
      }
    }
  }
}
.ant-list {
  background-color: #fff;
}

.ant-tree li span.ant-tree-checkbox {
  top: auto;
  height: auto;
  margin: 0 4px 0 2px;
  padding: 0;
}
.ant-radio {
  transform: translateY(-2px);
  .ant-radio-inner::after {
    top: 0px;
    left: 0px;
    width: 101%;
    height: 101%;
    border-radius: 100%;
  }
}
.ant-switch {
  /* 关闭的样式 */
  &:not(.ant-switch-checked) {
    background: #ececec;
    color: #bbbbbb;

    .ant-switch-inner {
      color: #7b82ff;
    }
  }
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #a3abff !important;
}
.ant-switch-checked {
  background-color: #7b82ff;
}
a {
  color: #7b82ff !important;
}
a:hover {
  color: #a3abff !important;
}
.ant-btn-link {
  color: #7b82ff;
}
.ant-select,
.ant-select:hover {
  background: #7b82ff !important;
  .ant-select-clear,
  .ant-select-clear:hover {
    background: #7b82ff !important;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #7b82ff !important;
}
.ant-select-focused:not(.ant-select-disabled)
  .ant-select:not(.ant-select-customize-input)
  .ant-selector {
  border-color: #7b82ff !important;
  box-shadow: 0 0 0 3px #7b82ff !important;
}
.ant-select:not(.editor) {
  &.ant-select-sm {
    height: 36px;
    .ant-select-arrow {
      height: 28px;
      width: 28px;
    }
  }

  .ant-select-arrow {
    background: linear-gradient(360deg, #8188ff 0%, #a78dff 100%);
    height: 32px;
    width: 36px;
    color: #6d7278;
    top: 6px;
    right: -1px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-select-arrow-icon {
      width: 8px;
      height: 4px;
      svg {
        display: none;
      }
      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        box-sizing: content-box;
        border-top: 4px solid #fff;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom-width: 0;
      }
    }
  }
  &.ant-select-open {
    .ant-select-arrow-icon {
      &::before {
        transform: rotate(180deg);
      }
    }
  }
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: linear-gradient(360deg, #8188ff 0%, #a78dff 100%) !important;
}
// button 样式复写
.ant-btn:not(.editor) {
  // Default 和 Primary
  &:not(.ant-btn-link):active, // 不是link
  &.ant-btn-primary:active,
  &.ant-btn-primary.ant-btn-background-ghost:active,
  &.ant-btn-primary:not(.ant-btn-background-ghost) {
    color: #fff;
    border-color: transparent !important;
    background: linear-gradient(360deg, #8188ff 0%, #a78dff 100%) !important;
  }

  &:not(.ant-btn-link):hover,
  &:not(.ant-btn-link):focus,
  &.ant-btn-primary:hover,
  &.ant-btn-primary.ant-btn-background-ghost:hover {
    color: #fff;
    border-color: transparent !important;
    background: linear-gradient(360deg, #9fa4ff 0%, #bba7ff 100%) !important;
  }

  // Danger
  &.ant-btn-danger:active,
  &.ant-btn-danger:not(.ant-btn-background-ghost) {
    color: #fff;
    border-color: transparent !important;
    background: linear-gradient(360deg, #ff8487 0%, #ff4d4f 100%) !important;
  }
  &.ant-btn-danger:hover,
  &.ant-btn-danger.ant-btn-background-ghost:hover {
    color: #fff;
    border-color: transparent !important;
    background: linear-gradient(360deg, #ffadae 0%, #ff8183 100%) !important;
  }
  // Link
  &.ant-btn-link {
    padding: 0;
  }

  // 最小按钮
  min-width: 100px;
  &.ant-btn-link,
  &.ant-btn-circle,
  &.ant-input-search-button {
    min-width: auto;
  }
}
:global(.ant-modal-content) {
  background: transparent;
}
:global(.ant-modal-header) {
  background: #2b3648;
  border-bottom: 1px solid #3e495b;
}
:global(.ant-modal-title) {
  font-size: 14px;
  color: #9cb6e6;
}
:global(.ant-modal-body) {
  background: #2b3648;
  color: #9cb6e6;
}
:global(.ant-modal-footer) {
  background: #2b3648;
  border-top: 1px solid #3e495b;
}
:global(.ant-modal-confirm-title) {
  color: #9cb6e6;
}
:global(.ant-modal-confirm-content) {
  color: #9cb6e6;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #7b82ff;
}
